<template>
  <v-container>
    <perseu-card title="Mudanças no sistema">
      <v-flex slot="content">
        <p>{{ new Date() | formatDate }}</p>
        <p>- Primeira versão do sistema com funcionalidades requisitadas</p>
        <p>- Cadastros requisitados</p>
      </v-flex>
    </perseu-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #35495e;
}
</style>
